import Vue from "vue";

import router from "./router";

import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";

import App from "./App.vue";
import store from "./store";

import VueGtm from "@gtm-support/vue2-gtm";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");

Vue.use(
  VueGtm({
    id: "G-86FD00B9LP",
    queryParams: {
      gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
      gtm_preview: "env-4",
      gtm_cookies_win: "x",
    },
    defer: false,
    compatibility: false,
    nonce: "2726c7f26c",
    enabled: true,
    debug: true,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  })
);
