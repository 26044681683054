<template>
  <v-container>
    <div class="header-portofolio">
      <v-img :src="enterprise.logo" contain width="100"></v-img>
      <a :href="enterprise.url" target="_blank">
        <v-icon class="icon-enlace"></v-icon>
        <span>{{ enterprise.url }}</span>
      </a>
      <span class="mx-4 d-none d-sm-block">|</span>
      <div>
        <a :href="enterprise.facebook" target="_blank">
          <v-icon class="icon-facebook"></v-icon>
        </a>
        <a :href="enterprise.linkedin" target="_blank">
          <v-icon class="icon-linkedin"></v-icon>
        </a>
        <a :href="enterprise.instragram" target="_blank">
          <v-icon class="icon-instagram"></v-icon>
        </a>
      </div>
    </div>
    <v-img :src="enterprise.banner"></v-img>
    <OurInvestmentsInfo :investmentInfo="enterpriseInfo" />
    <PersonalCard :personalInfo="CEOInfo" />
    <ApplyCard />
    <div class="text-center mb-5">
      <v-btn text @click="goToNextPage()">
        <span v-html="$t('global.see_next_startup')"></span>
        <v-icon class="icon-flecha-derecha"></v-icon
      ></v-btn>
    </div>
    <v-divider class="my-4 my-sm-8"></v-divider>
  </v-container>
</template>
<script>
import ApplyCard from "@/components/ApplyCard.vue";
import OurInvestmentsInfo from "@/components/OurInvestmentsInfo.vue";
import PersonalCard from "@/components/PersonalCard.vue";

import { GET_ENTERPRISES } from "@/store/actions/enterprises";

export default {
  data() {
    return {
      enterprises: [],
      enterprise: {},
      enterpriseInfo: {},
      CEOInfo: {},
      nextEnterprise: {},
    };
  },
  methods: {
    goToNextPage() {
      this.$router.push({
        name: "Enterprise",
        params: { id: this.nextEnterprise.href },
      });
    },
    updatePageData() {
      this.enterprises.forEach((element) => {
        if (element.href === this.$route.params.id) {
          this.enterprise = element;
        }
      });
      const index = this.enterprises.indexOf(this.enterprise);
      this.nextEnterprise =
        this.enterprises[(index + 1) % this.enterprises.length];
      this.enterpriseInfo = {
        legend: this.enterprise.title,
        description: this.enterprise.short_description,
        founders: this.enterprise.founders,
      };
      this.enterprise.founders.forEach((element) => {
        if (element.job_position === "CEO") {
          this.CEOInfo = element;
        }
      });
    },
    onClick() {
      this.$gtm.trackEvent({
        event: null,
        category: "Enterprise",
        action: "click",
        label: "Enterprise Page",
        value: 5000,
        noninteraction: false,
      });
    },
  },
  components: {
    ApplyCard,
    OurInvestmentsInfo,
    PersonalCard,
  },
  created: async function () {
    await this.$store.dispatch(GET_ENTERPRISES);
  },
  computed: {
    pageContent() {
      return this.$store.getters.getEnterprises;
    },
    updateLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    "$route.params.id": {
      handler: function () {
        this.updatePageData();
      },
      deep: true,
    },
    pageContent(content) {
      this.enterprises = content.data;
      this.updatePageData();
    },
    async updateLanguage() {
      await this.$store.dispatch(GET_ENTERPRISES);
    },
  },
  mounted() {
    this.$gtm.trackView("Enterprise", "/portfolio/:id");
  },
};
</script>
