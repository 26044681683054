/* eslint-disable no-unused-vars */

import { REQUEST_HOME_INFO_CARDS } from "@/store/actions/homeInfoCards";
import apiCall from "@/utils/api";

const state = {
  homeCards: {},
};

const getters = {
  getHomeCards: (state) => state.homeCards,
};

const actions = {
  [REQUEST_HOME_INFO_CARDS]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `info-cards/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_HOME_INFO_CARDS, request);
    return request;
  },
};

const mutations = {
  [REQUEST_HOME_INFO_CARDS]: (state, response) => {
    state.homeCards = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
