import Vue from "vue";
import Vuex from "vuex";

import applyNow from "./modules/applyNow";
import contactUs from "./modules/contactUs";

import language from "./modules/language";

import homeBanner from "./modules/homeBanner";
import homeInfoCards from "./modules/homeInfoCards";
import ourWork from "./modules/ourWork";
import portofolio from "./modules/portofolio";
import howToApply from "./modules/howToApply";
import cardApply from "./modules/cardApply";
import FAQ from "./modules/FAQ";
import staff from "./modules/staff";
import enterprises from "./modules/enterprises";
import aboutUs from "./modules/aboutUs";
import infoTalent from "./modules/infoTalent";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    applyNow,
    contactUs,
    language,
    homeBanner,
    homeInfoCards,
    ourWork,
    portofolio,
    howToApply,
    cardApply,
    FAQ,
    staff,
    enterprises,
    aboutUs,
    infoTalent,
  },
});
