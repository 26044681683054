<template>
  <div>
    <v-card
      class="info-card enterprises-card"
      v-for="item in EnterprisesCards"
      :key="item.id"
    >
      <div class="desc-card">
        <v-img :src="item.logo" class="img-small" height="80" contain></v-img>
        <h5 v-html="$t(item.name)" class="title-card my-4"></h5>
        <div v-html="$t(item.short_description)"></div>
        <div class="d-flex justify-center my-6">
          <a :href="item.facebook" target="_blank">
            <v-icon class="icon-facebook"></v-icon>
          </a>
          <a :href="item.linkedin" target="_blank">
            <v-icon class="icon-linkedin"></v-icon>
          </a>
          <a :href="item.instragram" target="_blank">
            <v-icon class="icon-instagram"></v-icon>
          </a>
          <a :href="item.url" target="_blank">
            <v-icon class="icon-enlace"></v-icon>
          </a>
        </div>
        <v-btn depressed color="primary" @click="goToPageDetail(item.href)">
          <span v-html="$t('global.learn_more')"></span>
          <v-icon class="icon-flecha-derecha"></v-icon>
        </v-btn>
      </div>
      <div class="img-card">
        <v-img :src="item.image_card" contain></v-img>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    EnterprisesCards: Array,
  },
  methods: {
    goToPageDetail(id) {
      this.$router.push({
        name: "Enterprise",
        params: { id: id },
      });
    },
  },
};
</script>
