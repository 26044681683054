<template>
  <v-container>
    <v-carousel
      v-model="model"
      hide-delimiters
      height="auto"
      :cycle="autoRotate"
    >
      <v-carousel-item v-for="(item, i) in mainBanner" :key="i">
        <v-card class="main-card">
          <div
            class="d-md-flex d-block flex-no-wrap justify-space-between align-center"
          >
            <div>
              <h2 v-html="$t(item.title)"></h2>
              <v-btn depressed color="primary">
                <div v-if="validButton(item.href)">
                  <a v-bind:href="item.href" target="_blank">
                    <span v-html="$t(button)"></span>
                  </a>
                </div>
                <div v-else>
                  <router-link :to="item.href">
                    <span v-html="$t(button)"></span>
                  </router-link>
                </div>
              </v-btn>
            </div>
            <div class="img-map">
              <v-img :src="item.image"></v-img>
            </div>
          </div>
        </v-card>
      </v-carousel-item>
    </v-carousel>
    <InfoCard :infoCards="infoCards" />
    <ApplyCard />
    <v-col md="8" offset-md="2" class="text-center my-8">
      <h4>{{ infoTalent.title }}</h4>
      <div class="my-8">{{ infoTalent.description }}</div>
      <v-btn depressed color="primary">
        <router-link :to="infoTalent.href">
          <span>{{ infoTalent.button }}</span>
          <v-icon class="icon-flecha-derecha"></v-icon>
        </router-link>
      </v-btn>
    </v-col>
    <ContactForm />
    <v-divider class="my-4 my-sm-8"></v-divider>
  </v-container>
</template>
<script>
import InfoCard from "@/components/InfoCard.vue";
import ApplyCard from "@/components/ApplyCard.vue";
import ContactForm from "@/components/ContactForm.vue";

import { REQUEST_HOME_BANNER } from "@/store/actions/homeBanner";
import { REQUEST_HOME_INFO_CARDS } from "@/store/actions/homeInfoCards";
import { REQUEST_INFO_TALENT } from "@/store/actions/infoTalent";

export default {
  data() {
    return {
      mainBanner: {},
      infoCards: [],
      infoTalent: {},
      model: 0,
      autoRotate: true,
      button: "home.main_card.button",
    };
  },
  components: {
    InfoCard,
    ApplyCard,
    ContactForm,
  },
  created: async function () {
    await this.$store.dispatch(REQUEST_HOME_BANNER);
    await this.$store.dispatch(REQUEST_HOME_INFO_CARDS);
    await this.$store.dispatch(REQUEST_INFO_TALENT);
  },
  computed: {
    bannerContent() {
      return this.$store.getters.getHomeBanner;
    },
    infoCardsContent() {
      return this.$store.getters.getHomeCards;
    },
    infoTalentContent() {
      return this.$store.getters.getInfoTalent;
    },
    updateLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    bannerContent(banner) {
      this.mainBanner = banner.data;
    },
    infoCardsContent(cards) {
      this.infoCards = cards.data;
    },
    infoTalentContent(content) {
      this.infoTalent = content.data[0];
    },
    async updateLanguage() {
      await this.$store.dispatch(REQUEST_HOME_BANNER);
      await this.$store.dispatch(REQUEST_HOME_INFO_CARDS);
      await this.$store.dispatch(REQUEST_INFO_TALENT);
    },
  },
  methods: {
    onClick() {
      this.$gtm.trackEvent({
        event: null,
        category: "Home",
        action: "click",
        label: "Home Page",
        value: 5000,
        noninteraction: false,
      });
    },
    validButton: function (label) {
      const reg =
        /^((https?|http|ftp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
      return reg.test(label);
    },
  },
  mounted() {
    this.$gtm.trackView("Home", "/");
  },
};
</script>
