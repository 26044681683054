export default [
  {
    countryName: "Afghanistan",
    countryShortCode: "AF",
    countryPhoneCode: "93",
  },
  {
    countryName: "Åland Islands",
    countryShortCode: "AX",
    countryPhoneCode: "",
  },
  {
    countryName: "Albania",
    countryShortCode: "AL",
    countryPhoneCode: "355",
  },
  {
    countryName: "Algeria",
    countryShortCode: "DZ",
    countryPhoneCode: "213",
  },
  {
    countryName: "American Samoa",
    countryShortCode: "AS",
    countryPhoneCode: "1 (684)",
  },
  {
    countryName: "Andorra",
    countryShortCode: "AD",
    countryPhoneCode: "376",
  },
  {
    countryName: "Angola",
    countryShortCode: "AO",
    countryPhoneCode: "244",
  },
  {
    countryName: "Anguilla",
    countryShortCode: "AI",
    countryPhoneCode: "1 (264)",
  },
  {
    countryName: "Antarctica",
    countryShortCode: "AQ",
    countryPhoneCode: "",
  },
  {
    countryName: "Antigua and Barbuda",
    countryShortCode: "AG",
    countryPhoneCode: "1 (268)",
  },
  {
    countryName: "Argentina",
    countryShortCode: "AR",
    countryPhoneCode: "54",
  },
  {
    countryName: "Armenia",
    countryShortCode: "AM",
    countryPhoneCode: "374",
  },
  {
    countryName: "Aruba",
    countryShortCode: "AW",
    countryPhoneCode: "297",
  },
  {
    countryName: "Australia",
    countryShortCode: "AU",
    countryPhoneCode: "61",
  },
  {
    countryName: "Austria",
    countryShortCode: "AT",
    countryPhoneCode: "43",
  },
  {
    countryName: "Azerbaijan",
    countryShortCode: "AZ",
    countryPhoneCode: "994",
  },
  {
    countryName: "Bahamas",
    countryShortCode: "BS",
    countryPhoneCode: "1 (242)",
  },
  {
    countryName: "Bahrain",
    countryShortCode: "BH",
    countryPhoneCode: "973",
  },
  {
    countryName: "Bangladesh",
    countryShortCode: "BD",
    countryPhoneCode: "880",
  },
  {
    countryName: "Barbados",
    countryShortCode: "BB",
    countryPhoneCode: "1 (246)",
  },
  {
    countryName: "Belarus",
    countryShortCode: "BY",
    countryPhoneCode: "375",
  },
  {
    countryName: "Belgium",
    countryShortCode: "BE",
    countryPhoneCode: "32",
  },
  {
    countryName: "Belize",
    countryShortCode: "BZ",
    countryPhoneCode: "501",
  },
  {
    countryName: "Benin",
    countryShortCode: "BJ",
    countryPhoneCode: "229",
  },
  {
    countryName: "Bermuda",
    countryShortCode: "BM",
    countryPhoneCode: "1 (441)",
  },
  {
    countryName: "Bhutan",
    countryShortCode: "BT",
    countryPhoneCode: "975",
  },
  {
    countryName: "Bolivia",
    countryShortCode: "BO",
    countryPhoneCode: "591",
  },
  {
    countryName: "Bonaire, Sint Eustatius and Saba",
    countryShortCode: "BQ",
    countryPhoneCode: "",
  },
  {
    countryName: "Bosnia and Herzegovina",
    countryShortCode: "BA",
    countryPhoneCode: "387",
  },
  {
    countryName: "Botswana",
    countryShortCode: "BW",
    countryPhoneCode: "267",
  },
  {
    countryName: "Bouvet Island",
    countryShortCode: "BV",
    countryPhoneCode: "",
  },
  {
    countryName: "Brazil",
    countryShortCode: "BR",
    countryPhoneCode: "55",
  },
  {
    countryName: "British Indian Ocean Territory",
    countryShortCode: "IO",
    countryPhoneCode: "1 (284)",
  },
  {
    countryName: "Brunei Darussalam",
    countryShortCode: "BN",
    countryPhoneCode: "673",
  },
  {
    countryName: "Bulgaria",
    countryShortCode: "BG",
    countryPhoneCode: "359",
  },
  {
    countryName: "Burkina Faso",
    countryShortCode: "BF",
    countryPhoneCode: "226",
  },
  {
    countryName: "Burundi",
    countryShortCode: "BI",
    countryPhoneCode: "257",
  },
  {
    countryName: "Cambodia",
    countryShortCode: "KH",
    countryPhoneCode: "855",
  },
  {
    countryName: "Cameroon",
    countryShortCode: "CM",
    countryPhoneCode: "237",
  },
  {
    countryName: "Canada",
    countryShortCode: "CA",
    countryPhoneCode: "",
  },
  {
    countryName: "Cape Verde",
    countryShortCode: "CV",
    countryPhoneCode: "238",
  },
  {
    countryName: "Cayman Islands",
    countryShortCode: "KY",
    countryPhoneCode: "1 (345)",
  },
  {
    countryName: "Central African Republic",
    countryShortCode: "CF",
    countryPhoneCode: "236",
  },
  {
    countryName: "Chad",
    countryShortCode: "TD",
    countryPhoneCode: "235",
  },
  {
    countryName: "Chile",
    countryShortCode: "CL",
    countryPhoneCode: "56",
  },
  {
    countryName: "China",
    countryShortCode: "CN",
    countryPhoneCode: "86",
  },
  {
    countryName: "Christmas Island",
    countryShortCode: "CX",
    countryPhoneCode: "",
  },
  {
    countryName: "Cocos (Keeling) Islands",
    countryShortCode: "CC",
    countryPhoneCode: "",
  },
  {
    countryName: "Colombia",
    countryShortCode: "CO",
    countryPhoneCode: "57",
  },
  {
    countryName: "Comoros",
    countryShortCode: "KM",
    countryPhoneCode: "269",
  },
  {
    countryName: "Congo, Republic of the (Brazzaville)",
    countryShortCode: "CG",
    countryPhoneCode: "242",
  },
  {
    countryName: "Congo, the Democratic Republic of the (Kinshasa)",
    countryShortCode: "CD",
    countryPhoneCode: "243",
  },
  {
    countryName: "Cook Islands",
    countryShortCode: "CK",
    countryPhoneCode: "682",
  },
  {
    countryName: "Costa Rica",
    countryShortCode: "CR",
    countryPhoneCode: "506",
  },
  {
    countryName: "Côte d'Ivoire, Republic of",
    countryShortCode: "CI",
    countryPhoneCode: "",
  },
  {
    countryName: "Croatia",
    countryShortCode: "HR",
    countryPhoneCode: "385",
  },
  {
    countryName: "Cuba",
    countryShortCode: "CU",
    countryPhoneCode: "53",
  },
  {
    countryName: "Curaçao",
    countryShortCode: "CW",
    countryPhoneCode: "599",
  },
  {
    countryName: "Cyprus",
    countryShortCode: "CY",
    countryPhoneCode: "357",
  },
  {
    countryName: "Czech Republic",
    countryShortCode: "CZ",
    countryPhoneCode: "420",
  },
  {
    countryName: "Denmark",
    countryShortCode: "DK",
    countryPhoneCode: "45",
  },
  {
    countryName: "Djibouti",
    countryShortCode: "DJ",
    countryPhoneCode: "",
  },
  {
    countryName: "Dominica",
    countryShortCode: "DM",
    countryPhoneCode: "1 (767)",
  },
  {
    countryName: "Dominican Republic",
    countryShortCode: "DO",
    countryPhoneCode: "1 (809/829/849)",
  },
  {
    countryName: "Ecuador",
    countryShortCode: "EC",
    countryPhoneCode: "593",
  },
  {
    countryName: "Egypt",
    countryShortCode: "EG",
    countryPhoneCode: "20",
  },
  {
    countryName: "El Salvador",
    countryShortCode: "SV",
    countryPhoneCode: "503",
  },
  {
    countryName: "Equatorial Guinea",
    countryShortCode: "GQ",
    countryPhoneCode: "240",
  },
  {
    countryName: "Eritrea",
    countryShortCode: "ER",
    countryPhoneCode: "291",
  },
  {
    countryName: "Estonia",
    countryShortCode: "EE",
    countryPhoneCode: "372",
  },
  {
    countryName: "Ethiopia",
    countryShortCode: "ET",
    countryPhoneCode: "251",
  },
  {
    countryName: "Falkland Islands (Islas Malvinas)",
    countryShortCode: "FK",
    countryPhoneCode: "",
  },
  {
    countryName: "Faroe Islands",
    countryShortCode: "FO",
    countryPhoneCode: "298",
  },
  {
    countryName: "Fiji",
    countryShortCode: "FJ",
    countryPhoneCode: "679",
  },
  {
    countryName: "Finland",
    countryShortCode: "FI",
    countryPhoneCode: "358",
  },
  {
    countryName: "France",
    countryShortCode: "FR",
    countryPhoneCode: "33",
  },
  {
    countryName: "French Guiana",
    countryShortCode: "GF",
    countryPhoneCode: "594",
  },
  {
    countryName: "French Polynesia",
    countryShortCode: "PF",
    countryPhoneCode: "",
  },
  {
    countryName: "French Southern and Antarctic Lands",
    countryShortCode: "TF",
    countryPhoneCode: "",
  },
  {
    countryName: "Gabon",
    countryShortCode: "GA",
    countryPhoneCode: "241",
  },
  {
    countryName: "Gambia, The",
    countryShortCode: "GM",
    countryPhoneCode: "220",
  },
  {
    countryName: "Georgia",
    countryShortCode: "GE",
    countryPhoneCode: "995",
  },
  {
    countryName: "Germany",
    countryShortCode: "DE",
    countryPhoneCode: "49",
  },
  {
    countryName: "Ghana",
    countryShortCode: "GH",
    countryPhoneCode: "233",
  },
  {
    countryName: "Gibraltar",
    countryShortCode: "GI",
    countryPhoneCode: "350",
  },
  {
    countryName: "Greece",
    countryShortCode: "GR",
    countryPhoneCode: "30",
  },
  {
    countryName: "Greenland",
    countryShortCode: "GL",
    countryPhoneCode: "299",
  },
  {
    countryName: "Grenada",
    countryShortCode: "GD",
    countryPhoneCode: "1 (473)",
  },
  {
    countryName: "Guadeloupe",
    countryShortCode: "GP",
    countryPhoneCode: "590",
  },
  {
    countryName: "Guam",
    countryShortCode: "GU",
    countryPhoneCode: "",
  },
  {
    countryName: "Guatemala",
    countryShortCode: "GT",
    countryPhoneCode: "502",
  },
  {
    countryName: "Guernsey",
    countryShortCode: "GG",
    countryPhoneCode: "44",
  },
  {
    countryName: "Guinea",
    countryShortCode: "GN",
    countryPhoneCode: "224",
  },
  {
    countryName: "Guinea-Bissau",
    countryShortCode: "GW",
    countryPhoneCode: "245",
  },
  {
    countryName: "Guyana",
    countryShortCode: "GY",
    countryPhoneCode: "592",
  },
  {
    countryName: "Haiti",
    countryShortCode: "HT",
    countryPhoneCode: "509",
  },
  {
    countryName: "Heard Island and McDonald Islands",
    countryShortCode: "HM",
    countryPhoneCode: "",
  },
  {
    countryName: "Holy See (Vatican City)",
    countryShortCode: "VA",
    countryPhoneCode: "",
  },
  {
    countryName: "Honduras",
    countryShortCode: "HN",
    countryPhoneCode: "504",
  },
  {
    countryName: "Hong Kong",
    countryShortCode: "HK",
    countryPhoneCode: "852",
  },
  {
    countryName: "Hungary",
    countryShortCode: "HU",
    countryPhoneCode: "36",
  },
  {
    countryName: "Iceland",
    countryShortCode: "IS",
    countryPhoneCode: "354",
  },
  {
    countryName: "India",
    countryShortCode: "IN",
    countryPhoneCode: "91",
  },
  {
    countryName: "Indonesia",
    countryShortCode: "ID",
    countryPhoneCode: "62",
  },
  {
    countryName: "Iran, Islamic Republic of",
    countryShortCode: "IR",
    countryPhoneCode: "98",
  },
  {
    countryName: "Iraq",
    countryShortCode: "IQ",
    countryPhoneCode: "964",
  },
  {
    countryName: "Ireland",
    countryShortCode: "IE",
    countryPhoneCode: "353",
  },
  {
    countryName: "Isle of Man",
    countryShortCode: "IM",
    countryPhoneCode: "44",
  },
  {
    countryName: "Israel",
    countryShortCode: "IL",
    countryPhoneCode: "972",
  },
  {
    countryName: "Italy",
    countryShortCode: "IT",
    countryPhoneCode: "39",
  },
  {
    countryName: "Jamaica",
    countryShortCode: "JM",
    countryPhoneCode: "1 (876)",
  },
  {
    countryName: "Japan",
    countryShortCode: "JP",
    countryPhoneCode: "81",
  },
  {
    countryName: "Jersey",
    countryShortCode: "JE",
    countryPhoneCode: "44",
  },
  {
    countryName: "Jordan",
    countryShortCode: "JO",
    countryPhoneCode: "962",
  },
  {
    countryName: "Kazakhstan",
    countryShortCode: "KZ",
    countryPhoneCode: "7",
  },
  {
    countryName: "Kenya",
    countryShortCode: "KE",
    countryPhoneCode: "254",
  },
  {
    countryName: "Kiribati",
    countryShortCode: "KI",
    countryPhoneCode: "686",
  },
  {
    countryName: "Korea, Democratic People's Republic of",
    countryShortCode: "KP",
    countryPhoneCode: "850",
  },
  {
    countryName: "Korea, Republic of",
    countryShortCode: "KR",
    countryPhoneCode: "82",
  },
  {
    countryName: "Kuwait",
    countryShortCode: "KW",
    countryPhoneCode: "965",
  },
  {
    countryName: "Kyrgyzstan",
    countryShortCode: "KG",
    countryPhoneCode: "996",
  },
  {
    countryName: "Laos",
    countryShortCode: "LA",
    countryPhoneCode: "856",
  },
  {
    countryName: "Latvia",
    countryShortCode: "LV",
    countryPhoneCode: "371",
  },
  {
    countryName: "Lebanon",
    countryShortCode: "LB",
    countryPhoneCode: "961",
  },
  {
    countryName: "Lesotho",
    countryShortCode: "LS",
    countryPhoneCode: "266",
  },
  {
    countryName: "Liberia",
    countryShortCode: "LR",
    countryPhoneCode: "231",
  },
  {
    countryName: "Libya",
    countryShortCode: "LY",
    countryPhoneCode: "218",
  },
  {
    countryName: "Liechtenstein",
    countryShortCode: "LI",
    countryPhoneCode: "423",
  },
  {
    countryName: "Lithuania",
    countryShortCode: "LT",
    countryPhoneCode: "370",
  },
  {
    countryName: "Luxembourg",
    countryShortCode: "LU",
    countryPhoneCode: "352",
  },
  {
    countryName: "Macao",
    countryShortCode: "MO",
    countryPhoneCode: "853",
  },
  {
    countryName: "Macedonia, Republic of",
    countryShortCode: "MK",
    countryPhoneCode: "389",
  },
  {
    countryName: "Madagascar",
    countryShortCode: "MG",
    countryPhoneCode: "261",
  },
  {
    countryName: "Malawi",
    countryShortCode: "MW",
    countryPhoneCode: "265",
  },
  {
    countryName: "Malaysia",
    countryShortCode: "MY",
    countryPhoneCode: "60",
  },
  {
    countryName: "Maldives",
    countryShortCode: "MV",
    countryPhoneCode: "960",
  },
  {
    countryName: "Mali",
    countryShortCode: "ML",
    countryPhoneCode: "223",
  },
  {
    countryName: "Malta",
    countryShortCode: "MT",
    countryPhoneCode: "356",
  },
  {
    countryName: "Marshall Islands",
    countryShortCode: "MH",
    countryPhoneCode: "692",
  },
  {
    countryName: "Martinique",
    countryShortCode: "MQ",
    countryPhoneCode: "596",
  },
  {
    countryName: "Mauritania",
    countryShortCode: "MR",
    countryPhoneCode: "222",
  },
  {
    countryName: "Mauritius",
    countryShortCode: "MU",
    countryPhoneCode: "230",
  },
  {
    countryName: "Mayotte",
    countryShortCode: "YT",
    countryPhoneCode: "",
  },
  {
    countryName: "Mexico",
    countryShortCode: "MX",
    countryPhoneCode: "52",
  },
  {
    countryName: "Micronesia, Federated States of",
    countryShortCode: "FM",
    countryPhoneCode: "691",
  },
  {
    countryName: "Moldova",
    countryShortCode: "MD",
    countryPhoneCode: "373",
  },
  {
    countryName: "Monaco",
    countryShortCode: "MC",
    countryPhoneCode: "377",
  },
  {
    countryName: "Mongolia",
    countryShortCode: "MN",
    countryPhoneCode: "976",
  },
  {
    countryName: "Montenegro",
    countryShortCode: "ME",
    countryPhoneCode: "382",
  },
  {
    countryName: "Montserrat",
    countryShortCode: "MS",
    countryPhoneCode: "1 (664)",
  },
  {
    countryName: "Morocco",
    countryShortCode: "MA",
    countryPhoneCode: "212",
  },
  {
    countryName: "Mozambique",
    countryShortCode: "MZ",
    countryPhoneCode: "258",
  },
  {
    countryName: "Myanmar",
    countryShortCode: "MM",
    countryPhoneCode: "95",
  },
  {
    countryName: "Namibia",
    countryShortCode: "NA",
    countryPhoneCode: "264",
  },
  {
    countryName: "Nauru",
    countryShortCode: "NR",
    countryPhoneCode: "674",
  },
  {
    countryName: "Nepal",
    countryShortCode: "NP",
    countryPhoneCode: "977",
  },
  {
    countryName: "Netherlands",
    countryShortCode: "NL",
    countryPhoneCode: "31",
  },
  {
    countryName: "New Caledonia",
    countryShortCode: "NC",
    countryPhoneCode: "687",
  },
  {
    countryName: "New Zealand",
    countryShortCode: "NZ",
    countryPhoneCode: "64",
  },
  {
    countryName: "Nicaragua",
    countryShortCode: "NI",
    countryPhoneCode: "505",
  },
  {
    countryName: "Niger",
    countryShortCode: "NE",
    countryPhoneCode: "227",
  },
  {
    countryName: "Nigeria",
    countryShortCode: "NG",
    countryPhoneCode: "234",
  },
  {
    countryName: "Niue",
    countryShortCode: "NU",
    countryPhoneCode: "",
  },
  {
    countryName: "Norfolk Island",
    countryShortCode: "NF",
    countryPhoneCode: "",
  },
  {
    countryName: "Northern Mariana Islands",
    countryShortCode: "MP",
    countryPhoneCode: "1 (670)",
  },
  {
    countryName: "Norway",
    countryShortCode: "NO",
    countryPhoneCode: "47",
  },
  {
    countryName: "Oman",
    countryShortCode: "OM",
    countryPhoneCode: "968",
  },
  {
    countryName: "Pakistan",
    countryShortCode: "PK",
    countryPhoneCode: "92",
  },
  {
    countryName: "Palau",
    countryShortCode: "PW",
    countryPhoneCode: "680",
  },
  {
    countryName: "Palestine, State of",
    countryShortCode: "PS",
    countryPhoneCode: "970",
  },
  {
    countryName: "Panama",
    countryShortCode: "PA",
    countryPhoneCode: "507",
  },
  {
    countryName: "Papua New Guinea",
    countryShortCode: "PG",
    countryPhoneCode: "675",
  },
  {
    countryName: "Paraguay",
    countryShortCode: "PY",
    countryPhoneCode: "595",
  },
  {
    countryName: "Peru",
    countryShortCode: "PE",
    countryPhoneCode: "51",
  },
  {
    countryName: "Philippines",
    countryShortCode: "PH",
    countryPhoneCode: "63",
  },
  {
    countryName: "Pitcairn",
    countryShortCode: "PN",
    countryPhoneCode: "",
  },
  {
    countryName: "Poland",
    countryShortCode: "PL",
    countryPhoneCode: "48",
  },
  {
    countryName: "Portugal",
    countryShortCode: "PT",
    countryPhoneCode: "351",
  },
  {
    countryName: "Puerto Rico",
    countryShortCode: "PR",
    countryPhoneCode: "",
  },
  {
    countryName: "Qatar",
    countryShortCode: "QA",
    countryPhoneCode: "974",
  },
  {
    countryName: "Réunion",
    countryShortCode: "RE",
    countryPhoneCode: "262",
  },
  {
    countryName: "Romania",
    countryShortCode: "RO",
    countryPhoneCode: "40",
  },
  {
    countryName: "Russian Federation",
    countryShortCode: "RU",
    countryPhoneCode: "7",
  },
  {
    countryName: "Rwanda",
    countryShortCode: "RW",
    countryPhoneCode: "250",
  },
  {
    countryName: "Saint Barthélemy",
    countryShortCode: "BL",
    countryPhoneCode: "",
  },
  {
    countryName: "Saint Helena, Ascension and Tristan da Cunha",
    countryShortCode: "SH",
    countryPhoneCode: "",
  },
  {
    countryName: "Saint Kitts and Nevis",
    countryShortCode: "KN",
    countryPhoneCode: "",
  },
  {
    countryName: "Saint Lucia",
    countryShortCode: "LC",
    countryPhoneCode: "1 (758)",
  },
  {
    countryName: "Saint Martin",
    countryShortCode: "MF",
    countryPhoneCode: "",
  },
  {
    countryName: "Saint Pierre and Miquelon",
    countryShortCode: "PM",
    countryPhoneCode: "",
  },
  {
    countryName: "Saint Vincent and the Grenadines",
    countryShortCode: "VC",
    countryPhoneCode: "",
  },
  {
    countryName: "Samoa",
    countryShortCode: "WS",
    countryPhoneCode: "685",
  },
  {
    countryName: "San Marino",
    countryShortCode: "SM",
    countryPhoneCode: "378",
  },
  {
    countryName: "Sao Tome and Principe",
    countryShortCode: "ST",
    countryPhoneCode: "239",
  },
  {
    countryName: "Saudi Arabia",
    countryShortCode: "SA",
    countryPhoneCode: "966",
  },
  {
    countryName: "Senegal",
    countryShortCode: "SN",
    countryPhoneCode: "221",
  },
  {
    countryName: "Serbia",
    countryShortCode: "RS",
    countryPhoneCode: "381",
  },
  {
    countryName: "Seychelles",
    countryShortCode: "SC",
    countryPhoneCode: "248",
  },
  {
    countryName: "Sierra Leone",
    countryShortCode: "SL",
    countryPhoneCode: "232",
  },
  {
    countryName: "Singapore",
    countryShortCode: "SG",
    countryPhoneCode: "65",
  },
  {
    countryName: "Sint Maarten (Dutch part)",
    countryShortCode: "SX",
    countryPhoneCode: "1 (721)",
  },
  {
    countryName: "Slovakia",
    countryShortCode: "SK",
    countryPhoneCode: "421",
  },
  {
    countryName: "Slovenia",
    countryShortCode: "SI",
    countryPhoneCode: "386",
  },
  {
    countryName: "Solomon Islands",
    countryShortCode: "SB",
    countryPhoneCode: "677",
  },
  {
    countryName: "Somalia",
    countryShortCode: "SO",
    countryPhoneCode: "27",
  },
  {
    countryName: "South Africa",
    countryShortCode: "ZA",
    countryPhoneCode: "",
  },
  {
    countryName: "South Georgia and South Sandwich Islands",
    countryShortCode: "GS",
    countryPhoneCode: "",
  },
  {
    countryName: "South Sudan",
    countryShortCode: "SS",
    countryPhoneCode: "211",
  },
  {
    countryName: "Spain",
    countryShortCode: "ES",
    countryPhoneCode: "34",
  },
  {
    countryName: "Sri Lanka",
    countryShortCode: "LK",
    countryPhoneCode: "94",
  },
  {
    countryName: "Sudan",
    countryShortCode: "SD",
    countryPhoneCode: "249",
  },
  {
    countryName: "Suriname",
    countryShortCode: "SR",
    countryPhoneCode: "597",
  },
  {
    countryName: "Swaziland",
    countryShortCode: "SZ",
    countryPhoneCode: "268",
  },
  {
    countryName: "Sweden",
    countryShortCode: "SE",
    countryPhoneCode: "46",
  },
  {
    countryName: "Switzerland",
    countryShortCode: "CH",
    countryPhoneCode: "41",
  },
  {
    countryName: "Syrian Arab Republic",
    countryShortCode: "SY",
    countryPhoneCode: "963",
  },
  {
    countryName: "Taiwan",
    countryShortCode: "TW",
    countryPhoneCode: "886",
  },
  {
    countryName: "Tajikistan",
    countryShortCode: "TJ",
    countryPhoneCode: "992",
  },
  {
    countryName: "Tanzania, United Republic of",
    countryShortCode: "TZ",
    countryPhoneCode: "255",
  },
  {
    countryName: "Thailand",
    countryShortCode: "TH",
    countryPhoneCode: "66",
  },
  {
    countryName: "Timor-Leste",
    countryShortCode: "TL",
    countryPhoneCode: "",
  },
  {
    countryName: "Togo",
    countryShortCode: "TG",
    countryPhoneCode: "228",
  },
  {
    countryName: "Tokelau",
    countryShortCode: "TK",
    countryPhoneCode: "690",
  },
  {
    countryName: "Tonga",
    countryShortCode: "TO",
    countryPhoneCode: "676",
  },
  {
    countryName: "Trinidad and Tobago",
    countryShortCode: "TT",
    countryPhoneCode: "1 (868)",
  },
  {
    countryName: "Tunisia",
    countryShortCode: "TN",
    countryPhoneCode: "216",
  },
  {
    countryName: "Turkey",
    countryShortCode: "TR",
    countryPhoneCode: "90",
  },
  {
    countryName: "Turkmenistan",
    countryShortCode: "TM",
    countryPhoneCode: "993",
  },
  {
    countryName: "Turks and Caicos Islands",
    countryShortCode: "TC",
    countryPhoneCode: "1 (649)",
  },
  {
    countryName: "Tuvalu",
    countryShortCode: "TV",
    countryPhoneCode: "688",
  },
  {
    countryName: "Uganda",
    countryShortCode: "UG",
    countryPhoneCode: "256",
  },
  {
    countryName: "Ukraine",
    countryShortCode: "UA",
    countryPhoneCode: "380",
  },
  {
    countryName: "United Arab Emirates",
    countryShortCode: "AE",
    countryPhoneCode: "971",
  },
  {
    countryName: "United Kingdom",
    countryShortCode: "GB",
    countryPhoneCode: "44",
  },
  {
    countryName: "United States",
    countryShortCode: "US",
    countryPhoneCode: "1",
  },
  {
    countryName: "United States Minor Outlying Islands",
    countryShortCode: "UM",
    countryPhoneCode: "",
  },
  {
    countryName: "Uruguay",
    countryShortCode: "UY",
    countryPhoneCode: "598",
  },
  {
    countryName: "Uzbekistan",
    countryShortCode: "UZ",
    countryPhoneCode: "998",
  },
  {
    countryName: "Vanuatu",
    countryShortCode: "VU",
    countryPhoneCode: "678",
  },
  {
    countryName: "Venezuela, Bolivarian Republic of",
    countryShortCode: "VE",
    countryPhoneCode: "58",
  },
  {
    countryName: "Vietnam",
    countryShortCode: "VN",
    countryPhoneCode: "84",
  },
  {
    countryName: "Virgin Islands, British",
    countryShortCode: "VG",
    countryPhoneCode: "",
  },
  {
    countryName: "Virgin Islands, U.S.",
    countryShortCode: "VI",
    countryPhoneCode: "",
  },
  {
    countryName: "Wallis and Futuna",
    countryShortCode: "WF",
    countryPhoneCode: "",
  },
  {
    countryName: "Western Sahara",
    countryShortCode: "EH",
    countryPhoneCode: "",
  },
  {
    countryName: "Yemen",
    countryShortCode: "YE",
    countryPhoneCode: "967",
  },
  {
    countryName: "Zambia",
    countryShortCode: "ZM",
    countryPhoneCode: "260",
  },
  {
    countryName: "Zimbabwe",
    countryShortCode: "ZW",
    countryPhoneCode: "263",
  },
];
