import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_SERVICES_BASE_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response);
  }
);

const apiCall = async ({ url, method, data, headers, params }) => {
  try {
    let request = await axios({
      method: method || "GET",
      url,
      data,
      headers,
      params,
    });
    return request.data || request || {};
  } catch (err) {
    const errorMessage = err.data?.errorMessage || err.data || err.statusText;
    throw new Error(errorMessage);
  }
};

export default apiCall;
