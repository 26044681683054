<template>
  <v-row no-gutters class="my-8 py-10 align-center">
    <v-col md="6" order="last" order-md="first">
      <div class="mr-10 pr-8 quote">
        <v-icon class="quote">mdi-format-quote-open</v-icon>
        <span v-html="$t(personalInfo.comment)" class="text-justify"></span>
        <v-icon class="quote">mdi-format-quote-close</v-icon>
      </div>
      <h2>{{ personalInfo.full_name }}</h2>
      <div>{{ personalInfo.job_position }}</div>
    </v-col>
    <v-col md="6">
      <v-img :src="personalInfo.image" class="rounded-image"></v-img>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    personalInfo: Object,
  },
};
</script>
