/* eslint-disable no-unused-vars */

import { REQUEST_STAFF_INFO } from "@/store/actions/staff";
import apiCall from "@/utils/api";

const state = {
  staffInfo: {},
};

const getters = {
  getStaffInfo: (state) => state.staffInfo,
};

const actions = {
  [REQUEST_STAFF_INFO]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `staff/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_STAFF_INFO, request);
    return request;
  },
};

const mutations = {
  [REQUEST_STAFF_INFO]: (state, response) => {
    state.staffInfo = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
