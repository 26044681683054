/* eslint-disable no-unused-vars */

import { REQUEST_CARD_APPLY_INFO } from "@/store/actions/cardApply";
import apiCall from "@/utils/api";

const state = {
  cardApplyInfo: {},
};

const getters = {
  getCardApplyInfo: (state) => state.cardApplyInfo,
};

const actions = {
  [REQUEST_CARD_APPLY_INFO]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `info-apply/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_CARD_APPLY_INFO, request);
    return request;
  },
};

const mutations = {
  [REQUEST_CARD_APPLY_INFO]: (state, response) => {
    state.cardApplyInfo = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
