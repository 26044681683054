/* eslint-disable no-unused-vars */

import { GET_ENTERPRISES } from "@/store/actions/enterprises";
import apiCall from "@/utils/api";

const state = {
  enterprises: {},
};

const getters = {
  getEnterprises: (state) => state.enterprises,
};

const actions = {
  [GET_ENTERPRISES]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `enterprises/${rootGetters.getLanguage}`,
    });

    commit(GET_ENTERPRISES, request);
    return request;
  },
};

const mutations = {
  [GET_ENTERPRISES]: (state, response) => {
    state.enterprises = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
