/* eslint-disable no-unused-vars */

import { REQUEST_PORTOFOLIO_CONTENT } from "@/store/actions/portofolio";
import apiCall from "@/utils/api";

const state = {
  ourPortofolioContent: {},
};

const getters = {
  getPortofolioContent: (state) => state.ourPortofolioContent,
};

const actions = {
  [REQUEST_PORTOFOLIO_CONTENT]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `portofolio/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_PORTOFOLIO_CONTENT, request);
    return request;
  },
};

const mutations = {
  [REQUEST_PORTOFOLIO_CONTENT]: (state, response) => {
    state.ourPortofolioContent = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
