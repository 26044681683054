<template>
  <v-footer class="footer">
    <router-link to="/">
      <v-img :src="mainLogo" title="Escalatec" class="main-logo"> </v-img>
    </router-link>
    <div>
      <span
        v-for="item in footerContent"
        :key="item.content"
        class="footer-content"
        v-html="$t(item.content, item.data)"
      >
      </span>
      <a
        class="footer-content"
        href="mailto:info@escalatec.com.bo"
        v-html="$t('footer.email')"
      ></a>
      <a href="https://www.linkedin.com/company/escalatec/" target="_blank">
        <v-icon class="icon-linkedin instagram-icon"></v-icon>
      </a>
    </div>
  </v-footer>
</template>
<style scoped>
.instagram-icon {
  align-items: flex-start;
  margin-left: -10px;
  align-self: flex-start;
}
</style>

<script>
export default {
  data() {
    return {
      mainLogo: require("../assets/images/logos/escalatec.svg"),
      footerContent: [
        {
          content: "footer.copyright",
          data: { year: new Date().getFullYear() },
        },
        { content: "footer.direction" },
        { content: "footer.phone_number" },
      ],
    };
  },
};
</script>
