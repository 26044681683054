<template>
  <v-container>
    <v-card class="info-card presentation-card">
      <h1 v-html="$t(mainContent.title)" class="title-card"></h1>
      <div class="desc-card">
        <div v-html="$t(mainContent.description)" class="my-4"></div>
        <v-list>
          <v-list-item
            v-for="item in mainContent.items"
            :key="item.id"
            v-html="$t(item.title)"
          ></v-list-item>
        </v-list>
      </div>
      <div class="img-card">
        <v-img :src="mainContent.image" class="rounded-image"></v-img>
      </div>
    </v-card>
    <ApplyCard />
    <v-divider class="my-4 my-sm-8"></v-divider>
  </v-container>
</template>
<script>
import { REQUEST_OUR_WORK_CONTENT } from "@/store/actions/ourWork";
import ApplyCard from "@/components/ApplyCard.vue";
export default {
  data() {
    return {
      mainContent: {},
    };
  },
  components: {
    ApplyCard,
  },
  created: async function () {
    await this.$store.dispatch(REQUEST_OUR_WORK_CONTENT);
  },
  computed: {
    pageContent() {
      return this.$store.getters.getOurWorkContent;
    },
    updateLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    pageContent(content) {
      this.mainContent = content.data;
    },
    async updateLanguage() {
      await this.$store.dispatch(REQUEST_OUR_WORK_CONTENT);
    },
  },
  methods: {
    onClick() {
      this.$gtm.trackEvent({
        event: null,
        category: "OurWork",
        action: "click",
        label: "Our Work Page",
        value: 5000,
        noninteraction: false,
      });
    },
  },
  mounted() {
    this.$gtm.trackView("OurWork", "/ourWork");
  },
};
</script>
