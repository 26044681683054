<template>
  <v-container>
    <h1 class="mb-10">{{ mainContent.title }}</h1>
    <h4>{{ mainContent.description }}</h4>
    <InfoBox :InfoCards="mainContent.cards" />
    <h3 v-html="$t(faq)" class="text-center my-8 py-10"></h3>
    <FAQ :FAQs="questions" />
    <ContactForm />
    <v-divider class="my-4 my-sm-8"></v-divider>
  </v-container>
</template>
<script>
import ContactForm from "@/components/ContactForm.vue";
import InfoBox from "@/components/InfoBox.vue";
import FAQ from "@/components/FAQ.vue";

import { REQUEST_FREQUENT_QUESTIONS } from "@/store/actions/FAQ";
import { REQUEST_HOW_TO_APPLY_INFO } from "@/store/actions/howToApply";

export default {
  data() {
    return {
      questions: [],
      mainContent: {},
      title: "how_to_apply.title",
      description: "how_to_apply.description",
      faq: "how_to_apply.faq",
    };
  },
  components: {
    ContactForm,
    InfoBox,
    FAQ,
  },
  created: async function () {
    await this.$store.dispatch(REQUEST_FREQUENT_QUESTIONS);
    await this.$store.dispatch(REQUEST_HOW_TO_APPLY_INFO);
  },
  computed: {
    pageContent() {
      return this.$store.getters.getQuestions;
    },
    cardsContent() {
      return this.$store.getters.getHowToApplyInfo;
    },
    updateLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    pageContent(content) {
      this.questions = content.data;
    },
    cardsContent(content) {
      this.mainContent = content.data;
    },
    async updateLanguage() {
      await this.$store.dispatch(REQUEST_FREQUENT_QUESTIONS);
      await this.$store.dispatch(REQUEST_HOW_TO_APPLY_INFO);
    },
  },
  methods: {
    onClick() {
      this.$gtm.trackEvent({
        event: null,
        category: "HowToApply",
        action: "click",
        label: "How To Apply Page",
        value: 5000,
        noninteraction: false,
      });
    },
  },
  mounted() {
    this.$gtm.trackView("HowToApply", "/howToApply");
  },
};
</script>
