import { REQUEST_SAVE_CONTACT_US } from "@/store/actions/contactUs";

import apiCall from "@/utils/api";

const state = {};

const getters = {};

const actions = {
  // eslint-disable-next-line no-unused-vars
  [REQUEST_SAVE_CONTACT_US]: async ({ commit, dispatch }, contactUs) => {
    return await apiCall({
      url: "mail/contactUs",
      method: "POST",
      data: contactUs,
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
