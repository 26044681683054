<template>
  <v-container>
    <div class="text-sm-left text-md-center mb-10">
      <h1 v-html="$t(mainContent.title)" class="mb-13"></h1>
      <h4 v-html="$t(mainContent.description)" class="pb-10"></h4>
    </div>
    <h2 v-html="$t(whoWeInvested)" class="mb-10 pb-10 text-center"></h2>
    <EnterprisesCard :EnterprisesCards="enterprises" />
    <ApplyCard />
    <v-divider class="my-4 my-sm-8"></v-divider>
  </v-container>
</template>
<script>
import ApplyCard from "@/components/ApplyCard.vue";
import EnterprisesCard from "@/components/EnterprisesCards.vue";

import { REQUEST_PORTOFOLIO_CONTENT } from "@/store/actions/portofolio";
export default {
  data() {
    return {
      mainContent: {},
      enterprises: [],
      whoWeInvested: "portofolio.who_we_invested",
    };
  },
  components: {
    ApplyCard,
    EnterprisesCard,
  },
  created: async function () {
    await this.$store.dispatch(REQUEST_PORTOFOLIO_CONTENT);
  },
  computed: {
    pageContent() {
      return this.$store.getters.getPortofolioContent;
    },
    updateLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    pageContent(content) {
      this.mainContent = content.data;
      this.enterprises = content.data.enterprises;
    },
    async updateLanguage() {
      await this.$store.dispatch(REQUEST_PORTOFOLIO_CONTENT);
    },
  },
  methods: {
    onClick() {
      this.$gtm.trackEvent({
        event: null,
        category: "Portfolio",
        action: "click",
        label: "Portfolio Page",
        value: 5000,
        noninteraction: false,
      });
    },
  },
  mounted() {
    this.$gtm.trackView("Portfolio", "/portfolio");
  },
};
</script>
