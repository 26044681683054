/* eslint-disable no-unused-vars */

import { REQUEST_OUR_WORK_CONTENT } from "@/store/actions/ourWork";
import apiCall from "@/utils/api";

const state = {
  ourWorkContent: {},
};

const getters = {
  getOurWorkContent: (state) => state.ourWorkContent,
};

const actions = {
  [REQUEST_OUR_WORK_CONTENT]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `our-work/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_OUR_WORK_CONTENT, request);
    return request;
  },
};

const mutations = {
  [REQUEST_OUR_WORK_CONTENT]: (state, response) => {
    state.ourWorkContent = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
