<template>
  <v-container class="about-us">
    <h1 class="mb-10">{{ aboutUsInfo.title }}</h1>
    <h4>{{ aboutUsInfo.description }}</h4>
    <v-row no-gutters class="mt-8 align-center">
      <v-col md="6">
        <v-list>
          <v-list-item v-for="item in aboutUsInfo.items" :key="item.id">{{
            item.title
          }}</v-list-item>
        </v-list>
      </v-col>
      <v-col md="6">
        <div class="badge">
          <span class="big-text">{{ aboutUsInfo.analyzed }}</span>
          <h3 v-html="$t('about_us.startup_analized')"></h3>
        </div>
        <div class="badge">
          <span class="big-text">{{ aboutUsInfo.invested }}</span>
          <h3 v-html="$t('about_us.startup_invested')"></h3>
        </div>
      </v-col>
    </v-row>
    <OurTeam :staffInfo="staff" />
    <ApplyCard />
    <v-col md="8" offset-md="2" class="text-center">
      <h4 v-html="$t('about_us.partner_solydes')" class="mb-12"></h4>
      <div class="d-block d-md-flex partners">
        <a href="https://solydes.org/" target="_blank" class="flex-grow-1">
          <v-img
            :src="require('../assets/images/Solydes-foundation.svg')"
            class="img-small"
          ></v-img>
        </a>
        <a
          href="https://www.solydesaceleradora.org/"
          target="_blank"
          class="flex-grow-1"
        >
          <v-img
            :src="require('../assets/images/Aceleradora-Solydes.svg')"
            class="img-small"
          ></v-img>
        </a>
      </div>
    </v-col>
    <v-divider class="my-4 my-sm-8"></v-divider>
  </v-container>
</template>
<script>
import ApplyCard from "@/components/ApplyCard.vue";
import OurTeam from "@/components/OurTeam.vue";

import { REQUEST_STAFF_INFO } from "@/store/actions/staff";
import { REQUEST_ABOUT_US_INFO } from "@/store/actions/aboutUs";
export default {
  data() {
    return {
      staff: {},
      aboutUsInfo: {},
      title: "about_us.title",
      description: "about_us.description",
      ourBackgroundList: [
        {
          content: "about_us.our_background.bullet_1",
        },
        {
          content: "about_us.our_background.bullet_2",
        },
        {
          content: "about_us.our_background.bullet_3",
        },
        {
          content: "about_us.our_background.bullet_4",
        },
      ],
    };
  },
  components: {
    ApplyCard,
    OurTeam,
  },
  created: async function () {
    await this.$store.dispatch(REQUEST_STAFF_INFO);
    await this.$store.dispatch(REQUEST_ABOUT_US_INFO);
  },
  computed: {
    staffInfo() {
      return this.$store.getters.getStaffInfo;
    },
    pageContent() {
      return this.$store.getters.getAboutUsInfo;
    },
    updateLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    staffInfo(content) {
      this.staff = content.data;
    },
    pageContent(content) {
      this.aboutUsInfo = content.data;
    },
    async updateLanguage() {
      await this.$store.dispatch(REQUEST_STAFF_INFO);
      await this.$store.dispatch(REQUEST_ABOUT_US_INFO);
    },
  },
  methods: {
    onClick() {
      this.$gtm.trackEvent({
        event: null,
        category: "AboutUs",
        action: "click",
        label: "About Us Page",
        value: 5000,
        noninteraction: false,
      });
    },
  },
  mounted() {
    this.$gtm.trackView("AboutUs", "/aboutUs");
  },
};
</script>
