import { SET_LANGUAGE } from "@/store/actions/language";

const state = {
  language: "ES",
};

const getters = {
  getLanguage: (state) => state.language,
};

const mutations = {
  [SET_LANGUAGE]: (state, params) => {
    state.language = params;
  },
};

export default {
  state,
  getters,
  mutations,
};
