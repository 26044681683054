/* eslint-disable no-unused-vars */

import { REQUEST_INFO_TALENT } from "@/store/actions/infoTalent";
import apiCall from "@/utils/api";

const state = {
  infoTalent: {},
};

const getters = {
  getInfoTalent: (state) => state.infoTalent,
};

const actions = {
  [REQUEST_INFO_TALENT]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `info-talent/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_INFO_TALENT, request);
    return request;
  },
};

const mutations = {
  [REQUEST_INFO_TALENT]: (state, response) => {
    state.infoTalent = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
