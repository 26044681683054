/* eslint-disable no-unused-vars */

import { REQUEST_HOW_TO_APPLY_INFO } from "@/store/actions/howToApply";
import apiCall from "@/utils/api";

const state = {
  howToApplyInfo: {},
};

const getters = {
  getHowToApplyInfo: (state) => state.howToApplyInfo,
};

const actions = {
  [REQUEST_HOW_TO_APPLY_INFO]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `how-to-apply/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_HOW_TO_APPLY_INFO, request);
    return request;
  },
};

const mutations = {
  [REQUEST_HOW_TO_APPLY_INFO]: (state, response) => {
    state.howToApplyInfo = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
