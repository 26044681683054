<template>
  <div class="contact-form">
    <h4 v-html="$t(formTitle)"></h4>
    <div v-html="$t(formDescription)"></div>
    <v-form ref="form" v-model="valid">
      <v-row no-gutters class="mt-8">
        <v-col cols="12" xs="12" md="4">
          <div class="form-label" v-html="$t(formName)"></div>
          <v-text-field
            v-model="name"
            required
            :placeholder="$t(formNamePlaceholder)"
            outlined
          ></v-text-field>
          <div class="form-label" v-html="$t(formEmail)"></div>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            :placeholder="$t(formEmailPlaceholder)"
            required
            outlined
          ></v-text-field>
          <div class="form-label" v-html="$t(formCountry)"></div>
          <CountrySelect
            v-model="country"
            topCountry="BO"
            :countryName="true"
            :placeholder="$t(formCountryPlaceholder)"
          />
          <div class="form-label" v-html="$t(formPhone)"></div>
          <div class="d-flex phone-field">
            <CountrySelect
              v-model="countryCode"
              topCountry="BO"
              :countryName="true"
              :countryPhoneCode="true"
              :placeholder="$t(formCountryCodePlaceholder)"
            />
            <v-text-field
              v-model="phone"
              :placeholder="$t(formPhonePlaceholder)"
              required
              outlined
            ></v-text-field>
          </div>
          <v-checkbox
            v-model="checkbox"
            :label="$t(formAvailable)"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" xs="12" md="7" offset-md="1">
          <div class="form-label" v-html="$t(formMessage)"></div>
          <v-textarea
            v-model="message"
            :placeholder="$t(formMsgPlaceholder)"
            :rules="messageRules"
            outlined
            rows="12"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-btn
        :disabled="!valid"
        color="primary"
        class="float-right"
        @click="validate"
        v-html="$t(button)"
      >
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import CountrySelect from "@/components/CountrySelect";
import i18n from "@/plugins/i18n";
import { REQUEST_SAVE_CONTACT_US } from "@/store/actions/contactUs";

export default {
  components: {
    CountrySelect,
  },
  data() {
    return {
      formTitle: "global.contactUsForm.title",
      formDescription: "global.contactUsForm.description",
      formName: "global.contactUsForm.name_lbl",
      formEmail: "global.contactUsForm.email_lbl",
      formCountry: "global.contactUsForm.country_lbl",
      formPhone: "global.contactUsForm.phone_number_lbl",
      formMessage: "global.contactUsForm.message_lbl",
      formAvailable: "global.contactUsForm.available_whatsapp_lbl",
      button: "global.contactUsForm.send_btn",
      formNamePlaceholder: "global.contactUsForm.name_placeholder",
      formEmailPlaceholder: "global.contactUsForm.email_placeholder",
      formCountryPlaceholder: "global.contactUsForm.country_placeholder",
      formCountryCodePlaceholder:
        "global.contactUsForm.country_code_placeholder",
      formPhonePlaceholder: "global.contactUsForm.phone_number_placeholder",
      formMsgPlaceholder: "global.contactUsForm.message_placeholder",
      valid: false,
      name: "",
      email: "",
      countryCode: {},
      phone: "",
      emailRules: [
        (v) => !!v || i18n.t("global.contactUsForm.email_required"),
        (v) =>
          /.+@.+\..+/.test(v) || i18n.t("global.contactUsForm.email_invalid"),
      ],
      messageRules: [
        (v) => !!v || i18n.t("global.contactUsForm.message_required"),
      ],
      select: null,
      checkbox: false,
      result: null,
      country: {},
      message: "",
    };
  },
  methods: {
    validate: async function () {
      try {
        this.$refs.form.validate();
        await this.$store.dispatch(REQUEST_SAVE_CONTACT_US, {
          name: this.name,
          email: this.email,
          country: this.country.countryName,
          phone_code: this.countryCode.countryPhoneCode,
          phone: this.phone,
          whatsapp: this.checkbox,
          message: this.message,
        });
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
