<template>
  <v-autocomplete
    :items="countries"
    item-text="countryName"
    item-value="text"
    :value="value"
    v-model="country"
    :country="country"
    :placeholder="placeholder"
    @change="onChange()"
    outlined
    return-object
  ></v-autocomplete>
</template>

<script>
import regions from "@/data/country.js";

export default {
  name: "CountrySelect",
  props: {
    countryName: Boolean,
    countryPhoneCode: Boolean,
    className: String,
    shortCodeDropdown: Boolean,
    autocomplete: Boolean,
    topCountry: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Select Country",
    },
    disablePlaceholder: {
      type: Boolean,
      default: false,
    },
    useI18n: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    ran: false,
    country: "",
  }),
  computed: {
    countries() {
      let countryList = regions.filter((region) => {
        if (this.countryName) {
          return region.countryName !== this.firstCountry;
        } else {
          return region.countryShortCode !== this.firstCountry;
        }
      });
      if (this.$i18n && this.useI18n) {
        countryList = countryList.map((country) => {
          let localeCountry = Object.assign({}, country);
          localeCountry.countryName = this.$t(country.countryName);
          if (this.countryPhoneCode) {
            localeCountry.countryName =
              "(" + localeCountry.countryName + ") " + country.countryPhoneCode;
          }
          return localeCountry;
        });
        countryList.sort((country1, country2) => {
          return country1.countryName > country2.countryName ? 1 : -1;
        });
      }
      if (this.topCountry) {
        countryList.unshift(this.firstCountry);
      }
      return countryList;
    },
    firstCountry() {
      if (this.countryName) {
        if (this.topCountry.length === 2) {
          const regionObj = regions.find((region) => {
            return region.countryShortCode === this.topCountry;
          });

          if (this.$i18n && this.useI18n) {
            let localeCountry = Object.assign({}, regionObj);
            localeCountry.countryName = this.$t(regionObj.countryName);
            if (this.countryPhoneCode) {
              localeCountry.countryName =
                "(" +
                localeCountry.countryName +
                ") " +
                regionObj.countryPhoneCode;
            }
            return localeCountry;
          }
          return regionObj.countryName;
        } else {
          return this.topCountry;
        }
      }
      if (this.topCountry) {
        return this.topCountry;
      }
      return "";
    },
    name() {
      return this.name;
    },
    value() {
      return this.country;
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.country);
    },
    topCountryName() {
      const regionObj = regions.find((region) => {
        if (this.countryName) {
          return region.countryName === this.firstCountry;
        } else {
          return region.countryShortCode === this.firstCountry;
        }
      });
      if (this.$i18n && this.useI18n) {
        return this.$t(regionObj.countryName);
      }
      return this.shortCodeDropdown
        ? regionObj.countryShortCode
        : regionObj.countryName;
    },
  },
};
</script>
