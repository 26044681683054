<template>
  <div class="info-box">
    <div class="info-box-card" v-for="item in InfoCards" :key="item.title">
      <h5 v-html="$t(item.title)"></h5>
      <div v-html="$t(item.description)" class="my-4"></div>
      <v-list>
        <v-list-item v-for="listItem in item.items" :key="listItem.title">{{
          listItem.title
        }}</v-list-item>
      </v-list>
      <v-btn depressed color="primary">
        <router-link :to="item.href">
          <span>{{ item.button }}</span>
          <v-icon class="icon-flecha-derecha"></v-icon>
        </router-link>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    InfoCards: Array,
  },
};
</script>
