<template>
  <v-row no-gutters>
    <v-col cols="12" sm="12" md="8" offset-md="2">
      <v-expansion-panels multiple class="expansion-panels">
        <v-expansion-panel v-for="(item, i) in FAQs" :key="i">
          <v-expansion-panel-header>{{
            $t(item.question)
          }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ $t(item.answer) }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      questionsList: [
        {
          question: "how_to_apply.question_1",
          answer: "how_to_apply.answer_1",
        },
        {
          question: "how_to_apply.question_2",
          answer: "how_to_apply.answer_2",
        },
        {
          question: "how_to_apply.question_3",
          answer: "how_to_apply.answer_3",
        },
        {
          question: "how_to_apply.question_4",
          answer: "how_to_apply.answer_4",
        },
        {
          question: "how_to_apply.question_5",
          answer: "how_to_apply.answer_5",
        },
      ],
    };
  },
  props: {
    FAQs: Array,
  },
};
</script>
