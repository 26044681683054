import { REQUEST_SAVE_APPLY_NOW } from "@/store/actions/applyNow";

import apiCall from "@/utils/api";

const state = {};

const getters = {};

const actions = {
  // eslint-disable-next-line no-unused-vars
  [REQUEST_SAVE_APPLY_NOW]: async ({ commit, dispatch }, record) => {
    let formData = new FormData();
    formData.append("file", record.file);
    formData.append("name", record.name);
    formData.append("email", record.email);
    formData.append("phone_code", record.phone_code);
    formData.append("phone", record.phone);
    formData.append("whatsapp", record.whatsapp);
    formData.append("industry", record.industry);
    formData.append("country", record.country);
    formData.append("elapsed_time", record.elapsed_time);
    formData.append("website", record.website);
    formData.append("investment_url", record.investment_url);
    formData.append("previous_investment", record.previous_investment);
    formData.append("part_of_accelerator", record.part_of_accelerator);
    formData.append("funds", record.funds);
    formData.append("message", record.message);

    return await apiCall({
      url: "mail/applyNow",
      method: "POST",
      data: formData,
    });
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
