import Vue from "vue";
import VueRouter from "vue-router";

import HomeEsc from "@/views/HomeEsc.vue";
import AboutUS from "@/views/AboutUs.vue";
import OurWork from "@/views/OurWork.vue";
import Portofolio from "@/views/PortofolioEsc.vue";
import HowToApply from "@/views/HowToApply.vue";
import ApplyNow from "@/views/ApplyNow.vue";
import EnterpriseDetail from "@/views/EnterpriseDetail.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeEsc,
  },
  {
    path: "/aboutUs",
    name: "AboutUs",
    component: AboutUS,
  },
  {
    path: "/ourWork",
    name: "OurWork",
    component: OurWork,
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portofolio,
  },
  {
    path: "/howToApply",
    name: "HowToApply",
    component: HowToApply,
  },
  {
    path: "/applyNow",
    name: "ApplyNow",
    component: ApplyNow,
  },
  {
    path: "/portfolio/:id",
    name: "Enterprise",
    component: EnterpriseDetail,
    props: true,
  },
  {
    path: "**",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
