/* eslint-disable no-unused-vars */

import { REQUEST_FREQUENT_QUESTIONS } from "@/store/actions/FAQ";
import apiCall from "@/utils/api";

const state = {
  frequentAskedQuestions: {},
};

const getters = {
  getQuestions: (state) => state.frequentAskedQuestions,
};

const actions = {
  [REQUEST_FREQUENT_QUESTIONS]: async ({ commit, dispatch, rootGetters }) => {
    let request = await apiCall({
      url: `faqs/${rootGetters.getLanguage}`,
    });

    commit(REQUEST_FREQUENT_QUESTIONS, request);
    return request;
  },
};

const mutations = {
  [REQUEST_FREQUENT_QUESTIONS]: (state, response) => {
    state.frequentAskedQuestions = response;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
