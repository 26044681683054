<template>
  <div>
    <v-col md="8" offset-md="2" class="text-center my-8">
      <h3 v-html="$t(staffInfo.title)" class="mb-8"></h3>
      <div v-html="$t(staffInfo.description)"></div>
    </v-col>
    <div class="team-cards">
      <v-card v-for="item in staffInfo.team" :key="item.name">
        <v-img :src="item.image"></v-img>
        <v-card-text>
          <h2>{{ item.name }}</h2>
          <div class="my-5">{{ item.job_position }}</div>
          <a
            :href="item.linkedin"
            target="_blank"
            class="d-flex align-baseline"
          >
            <v-icon class="icon-linkedin mr-1"></v-icon>
            <span class="align-self-center">LinkedIn</span>
          </a>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    staffInfo: Object,
  },
};
</script>
