<template>
  <div>
    <v-card class="info-card apply-card">
      <h4 class="title-card">{{ mainContent.title }}</h4>
      <div class="desc-card">
        <div class="mb-8">{{ mainContent.description }}</div>
        <v-btn depressed color="primary">
          <router-link :to="mainContent.href">
            <span>{{ mainContent.button }}</span>
            <v-icon class="icon-flecha-derecha"></v-icon>
          </router-link>
        </v-btn>
      </div>
      <div class="img-card">
        <v-img :src="mainContent.image" contain></v-img>
      </div>
    </v-card>
  </div>
</template>
<script>
import { REQUEST_CARD_APPLY_INFO } from "@/store/actions/cardApply";
export default {
  data() {
    return {
      mainContent: {},
    };
  },
  created: async function () {
    await this.$store.dispatch(REQUEST_CARD_APPLY_INFO);
  },
  computed: {
    pageContent() {
      return this.$store.getters.getCardApplyInfo;
    },
    updateLanguage() {
      return this.$store.getters.getLanguage;
    },
  },
  watch: {
    pageContent(content) {
      this.mainContent = content.data[0];
    },
    async updateLanguage() {
      await this.$store.dispatch(REQUEST_CARD_APPLY_INFO);
    },
  },
};
</script>
