<template>
  <div class="header">
    <v-app-bar absolute class="d-none d-md-block">
      <v-toolbar-title>
        <router-link to="/">
          <v-img :src="mainLogo" title="Escalatec" class="main-logo"> </v-img>
        </router-link>
      </v-toolbar-title>

      <div class="d-flex align-center">
        <v-btn
          text
          v-for="item in menuItemsList"
          :key="item.title"
          :color="item.color"
        >
          <router-link :to="item.path">
            <span v-html="$t(item.title)"></span>
          </router-link>
        </v-btn>
        <v-icon class="icon-globo"></v-icon>
        <v-btn-toggle v-model="toggle_exclusive" dense group>
          <v-btn text @click="setLanguage('es')"> ES </v-btn>
          <v-btn text @click="setLanguage('en')"> EN </v-btn>
        </v-btn-toggle>
      </div>
    </v-app-bar>
    <v-app-bar class="d-block d-md-none" prominent>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-img :src="mainLogo" title="Escalatec" class="main-logo"> </v-img>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      class="nav-drawer d-block d-md-none"
      hide-overlay
    >
      <div class="d-flex justify-space-between">
        <router-link to="/">
          <v-img :src="mainLogo" title="Escalatec" class="main-logo"> </v-img>
        </router-link>
        <v-icon @click.stop="drawer = !drawer">mdi-close</v-icon>
      </div>
      <v-list nav class="my-8">
        <v-list-tile
          v-for="item in menuItemsList"
          :key="item.title"
          :name="item.title"
        >
          <v-list-tile-content :name="item.title">
            <v-btn text :color="item.color">
              <router-link :to="item.path">
                <div v-html="$t(item.title)"></div>
              </router-link>
            </v-btn>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-divider></v-divider>
      <div class="d-flex">
        <v-icon class="icon-globo"></v-icon>
        <v-btn-toggle v-model="toggle_exclusive" dense group>
          <v-btn text @click="setLanguage('es')"> ES </v-btn>
          <v-btn text @click="setLanguage('en')"> EN </v-btn>
        </v-btn-toggle>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { SET_LANGUAGE } from "@/store/actions/language";
export default {
  data() {
    return {
      mainLogo: require("../assets/images/logos/escalatec.svg"),
      drawer: false,
      group: null,
      languageSelected: "",
      toggle_exclusive: 0,
      menuItemsList: [
        { title: "nav.who_we_are", path: "/aboutUs" },
        { title: "nav.what_do_we_do", path: "/ourWork" },
        { title: "nav.portofolio", path: "/portfolio" },
        { title: "nav.how_to_apply", path: "/howToApply" },
        { title: "nav.apply_now", path: "/applyNow", color: "primary" },
      ],
      languages: [
        { title: "nav.languages.english", value: "en" },
        { title: "nav.languages.spanish", value: "es" },
      ],
      locales: {
        es: "nav.languages.spanish",
        en: "nav.languages.english",
      },
    };
  },
  methods: {
    setLanguage(value) {
      this.$i18n.locale = value;
      this.$store.commit(SET_LANGUAGE, value.toUpperCase());
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>
