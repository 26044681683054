<template>
  <v-app>
    <v-main>
      <div class="d-flex flex-column">
        <HeaderEsc />
        <router-view class="flex-grow-1" />
        <FooterEsc />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import HeaderEsc from "@/components/HeaderEsc.vue";
import FooterEsc from "@/components/FooterEsc.vue";
export default {
  name: "App",
  components: {
    HeaderEsc,
    FooterEsc,
  },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
@import "assets/theme/_app.scss";
</style>
