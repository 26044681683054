<template>
  <div>
    <v-card
      class="info-card grayscale-images"
      v-for="item in infoCards"
      :key="item.title"
    >
      <h4 v-html="$t(item.title)" class="title-card"></h4>
      <div class="desc-card">
        <div v-html="$t(item.description)"></div>
        <v-btn depressed color="primary">
          <router-link :to="item.href">
            <span v-html="$t('global.learn_more')"></span>
            <v-icon class="icon-flecha-derecha"></v-icon>
          </router-link>
        </v-btn>
      </div>
      <div class="img-card">
        <v-img :src="item.image" contain></v-img>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // infoCards: [
      //   {
      //     title: "home.info_cards.title_card_1",
      //     description: "home.info_cards.desc_card_1",
      //     path: "/aboutUs",
      //     button: "global.learn_more",
      //     image: require("../assets/images/Jovenes-emprededores-escritorio.png"),
      //   },
      //   {
      //     title: "home.info_cards.title_card_2",
      //     description: "home.info_cards.desc_card_2",
      //     path: "/ourWork",
      //     button: "global.learn_more",
      //     image: require("../assets/images/Mujer-emprendedora-Escritorio.png"),
      //   },
      // ],
    };
  },
  props: {
    infoCards: Array,
  },
};
</script>
