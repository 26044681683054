<template>
  <v-row no-gutters class="panel-bg mt-8">
    <v-col cols="12" sm="12" md="7" class="mb-8">
      <h4 v-html="$t(investmentInfo.legend)" class="mb-4"></h4>
      <div v-html="$t(investmentInfo.description)"></div>
    </v-col>
    <v-col cols="12" sm="12" md="4" offset-md="1">
      <h4 v-html="$t('global.founders')" class="mb-4"></h4>
      <div
        v-for="item in investmentInfo.founders"
        :key="item.name"
        class="d-flex"
      >
        <a :href="item.linkedin" target="_blank" class="d-flex align-baseline">
          <v-icon class="icon-enlace-externo mr-3"></v-icon>
          <div class="text-decoration-underline">
            {{ item.full_name }} - {{ item.job_position }}
          </div>
        </a>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    investmentInfo: Object,
  },
};
</script>
