<template>
  <v-container class="not-found-page">
    <v-img :src="infoIcon" class="info-icon"></v-img>
    <h1 v-html="$t('not_found.error_message')" class="text-center mb-8"></h1>
    <div v-html="$t('not_found.description')" class="text-center mb-4"></div>
    <div class="text-center">
      <v-btn depressed color="primary">
        <router-link to="/">
          <span v-html="$t('not_found.go_to_main_page')"></span>
          <v-icon class="icon-flecha-derecha"></v-icon>
        </router-link>
      </v-btn>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      infoIcon: require("../assets/icons/info.svg"),
    };
  },
};
</script>
