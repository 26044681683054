<template>
  <v-container>
    <h1 v-html="$t(title)" class="mb-10"></h1>
    <v-row no-gutters><v-col md="8" v-html="$t(description)"></v-col></v-row>
    <div class="d-flex my-6">
      <v-icon class="icon-reloj mr-2"></v-icon>
      <span v-html="$t(formDuration)"></span>
    </div>
    <v-divider></v-divider>
    <v-alert
      border="left"
      dense
      text
      class="info-alert my-4"
      icon="icon-informacion-icono"
    >
      <span v-html="$t(infoAlert)"></span>
    </v-alert>
    <v-form ref="form" class="form-wrapper" v-model="valid">
      <h5 v-html="$t('apply_now.contact_info')" class="my-6"></h5>
      <div class="form-label" v-html="$t(formName)"></div>
      <v-text-field
        v-model="name"
        required
        :placeholder="$t(formNamePlaceholder)"
        outlined
      ></v-text-field>
      <div class="form-label" v-html="$t(formEmail)"></div>
      <v-text-field
        v-model="email"
        :rules="emailRules"
        :placeholder="$t(formEmailPlaceholder)"
        required
        outlined
      ></v-text-field>
      <div class="form-label" v-html="$t(formPhone)"></div>
      <div class="d-flex mr-4 phone-field">
        <CountrySelect
          v-model="countryCode"
          topCountry="BO"
          :countryName="true"
          :countryPhoneCode="true"
          :placeholder="$t(formCountryCodePlaceholder)"
        />
        <v-text-field
          v-model="phone"
          :placeholder="$t(formPhonePlaceholder)"
          required
          outlined
        ></v-text-field>
      </div>
      <v-checkbox v-model="checkbox" :label="$t(formAvailable)"></v-checkbox>
      <h5 v-html="$t('apply_now.startup_info')" class="my-6"></h5>
      <div class="form-label" v-html="$t('apply_now.industry')"></div>
      <v-select
        v-model="industry"
        :items="industries"
        item-text="name"
        :item-value="$t(name)"
        outlined
        :placeholder="$t('apply_now.industry_placeholder')"
        return-object
      ></v-select>
      <div class="form-label" v-html="$t(formCountry)"></div>
      <CountrySelect
        v-model="country"
        topCountry="BO"
        :countryName="true"
        :placeholder="$t(formCountryPlaceholder)"
      />
      <div class="form-label" v-html="$t('apply_now.startup_age')"></div>
      <v-radio-group v-model="age" row>
        <v-radio
          :label="$t('apply_now.radio_age_option_0')"
          value="age-range-1"
        ></v-radio>
        <v-radio
          :label="$t('apply_now.radio_age_option_1')"
          value="age-range-2"
        ></v-radio>
        <v-radio
          :label="$t('apply_now.radio_age_option_2')"
          value="age-range-3"
        ></v-radio>
        <v-radio
          :label="$t('apply_now.radio_age_option_5')"
          value="age-range-4"
        ></v-radio>
      </v-radio-group>
      <div class="form-label" v-html="$t('apply_now.website')"></div>
      <v-text-field
        v-model="website"
        required
        :placeholder="$t('apply_now.website_placeholder')"
        outlined
      ></v-text-field>
      <div class="form-label" v-html="$t('apply_now.file_link')"></div>
      <v-file-input
        show-size
        truncate-length="15"
        v-model="file"
      ></v-file-input>
      <v-text-field
        v-model="url"
        required
        :placeholder="$t('apply_now.url')"
        outlined
      ></v-text-field>
      <h5 v-html="$t('apply_now.financial_info')" class="my-6"></h5>
      <div
        class="form-label"
        v-html="$t('apply_now.startup_previus_investment')"
      ></div>
      <v-radio-group v-model="previousInvestment">
        <v-radio :label="$t('apply_now.radio_yes')" value="yes"></v-radio>
        <v-radio :label="$t('apply_now.radio_no')" value="no"></v-radio>
      </v-radio-group>
      <div
        class="form-label"
        v-html="$t('apply_now.startup_part_of_accelarator')"
      ></div>
      <v-radio-group v-model="partOfAccelerator">
        <v-radio :label="$t('apply_now.radio_yes')" value="yes"></v-radio>
        <v-radio :label="$t('apply_now.radio_no')" value="no"></v-radio>
      </v-radio-group>
      <h5 v-html="$t('apply_now.current_fundraising')" class="my-6"></h5>
      <div
        class="form-label"
        v-html="$t('apply_now.what_are_you_looking')"
      ></div>
      <v-select
        v-model="investment"
        :items="foundMounts"
        item-text="name"
        :item-value="$t(name)"
        outlined
        :placeholder="$t('apply_now.mount_placeholder')"
        return-object
      ></v-select>
      <h5 v-html="$t('apply_now.additional_info')" class="my-6"></h5>
      <div class="form-label" v-html="$t('apply_now.message_optional')"></div>
      <v-textarea
        v-model="message"
        :placeholder="$t(formMsgPlaceholder)"
        outlined
        rows="12"
      ></v-textarea>

      <v-btn
        :disabled="!valid"
        color="primary"
        class="float-right"
        @click="validate"
        v-html="$t('apply_now.send_form')"
      >
      </v-btn>
    </v-form>
    <v-divider class="my-4 my-sm-8"></v-divider>
  </v-container>
</template>
<script>
import i18n from "@/plugins/i18n";
import CountrySelect from "@/components/CountrySelect";
import { REQUEST_SAVE_APPLY_NOW } from "@/store/actions/applyNow";

export default {
  components: {
    CountrySelect,
  },
  data() {
    return {
      valid: false,
      name: "",
      email: "",
      countryCode: {},
      phone: "",
      industry: {},
      industriesList: [
        "agtech",
        "ai",
        "big_data",
        "blockchain",
        "edtech",
        "fintech",
        "healthtech",
        "insurance",
        "iot",
        "logístics",
        "marketplace",
        "real_estate",
        "saas",
        "low_carbon",
      ],
      country: {},
      age: "",
      website: "",
      url: "",
      previousInvestment: "",
      partOfAccelerator: "",
      foundMountsList: ["pre-seed", "seed", "serie A", "serie B", "serie C"],
      investment: {},
      message: "",
      emailRules: [
        (v) => !!v || i18n.t("global.contactUsForm.email_required"),
        (v) =>
          /.+@.+\..+/.test(v) || i18n.t("global.contactUsForm.email_invalid"),
      ],
      select: null,
      checkbox: false,
      result: null,
      file: null,
      title: "apply_now.title",
      description: "apply_now.description",
      formDuration: "apply_now.form_duration",
      infoAlert: "apply_now.info_alert",
      formName: "global.contactUsForm.name_lbl",
      formEmail: "global.contactUsForm.email_lbl",
      formCountry: "global.contactUsForm.country_lbl",
      formPhone: "global.contactUsForm.phone_number_lbl",
      formMessage: "global.contactUsForm.message_lbl",
      formAvailable: "global.contactUsForm.available_whatsapp_lbl",
      formNamePlaceholder: "global.contactUsForm.name_placeholder",
      formEmailPlaceholder: "global.contactUsForm.email_placeholder",
      formCountryPlaceholder: "global.contactUsForm.country_placeholder",
      formCountryCodePlaceholder:
        "global.contactUsForm.country_code_placeholder",
      formPhonePlaceholder: "global.contactUsForm.phone_number_placeholder",
      formMsgPlaceholder: "global.contactUsForm.message_placeholder",
    };
  },
  computed: {
    industries() {
      let result = this.industriesList.map((record) => {
        return {
          name: this.$t("industries." + record),
          code: record,
        };
      });
      result.push({
        name: this.$t("industries.other"),
        code: "other",
      });
      return result;
    },
    foundMounts() {
      let result = this.foundMountsList.map((record) => {
        return {
          name: this.$t("funds." + record),
          code: record,
        };
      });
      result.sort((record1, record2) => {
        return record1.name > record2.name ? 1 : -1;
      });
      return result;
    },
  },
  methods: {
    validate: async function () {
      try {
        this.$refs.form.validate();
        await this.$store.dispatch(REQUEST_SAVE_APPLY_NOW, {
          file: this.file,
          name: this.name,
          email: this.email,
          phone_code: this.countryCode.countryPhoneCode,
          phone: this.phone,
          whatsapp: this.checkbox,
          industry: this.industry.name,
          country: this.countryCode.countryName,
          elapsed_time: this.age,
          website: this.website,
          investment_url: this.url,
          previous_investment: this.previousInvestment,
          part_of_accelerator: this.partOfAccelerator,
          funds: this.investment.name,
          message: this.message,
        });
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } catch (error) {
        console.log(error);
      }
    },
    onClick() {
      this.$gtm.trackEvent({
        event: null,
        category: "ApplyNow",
        action: "click",
        label: "Apply Now Page",
        value: 5000,
        noninteraction: false,
      });
    },
  },
  mounted() {
    this.$gtm.trackView("ApplyNow", "/applyNow");
  },
};
</script>
